
const doDrawArea= (imgUrl, list,fn)=>{
    if(!imgUrl||imgUrl==''){
        fn('')
        return
    }
    var canvas = document.createElement('canvas')
    //  由于弹窗，确保已获取到
    //  可以理解为一个画笔，可画路径、矩形、文字、图像
    var context = canvas.getContext('2d')
    var img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.src = imgUrl
    img.onload = function () {
        if (img.complete) {
            canvas.setAttribute("width", img.width)
            canvas.setAttribute("height", img.height)
            //  绘制图片
            context.drawImage(img, 0, 0, img.width, img.height)
            context.fillStyle = 'rgba(255,0,0,0.7)'
            list.forEach(item => {
                if (item.judge != '') {
                    if (item.judge == 'allRight') {
                        let width = item.config.width * 0.8
                        width = width < 200 ? 200 : width
                        let height = item.config.height
                        height = height < 200 ? 200 : height
                        context.moveTo(item.config.x + width * 0.7, item.config.y + height * 0.5)
                        context.lineTo(item.config.x + width * 0.7 + height * 0.1, item.config.y + height * 0.6)
                        context.moveTo(item.config.x + width * 0.7 + height * 0.1, item.config.y + height * 0.6)
                        context.lineTo(item.config.x + width * 0.7 + height * 0.4, item.config.y + height * 0.3)
                        context.strokeStyle = "rgba(255,0,0,0.7)"
                        context.lineWidth = 4;
                        context.stroke()
                        // context.font ="60px Arial bolder"
                        // context.fillText('✔',item.config.x+(item.config.width*.6),item.config.y+90)
                    } else if (item.judge == 'allWrong') {
                        let width = item.config.width * 0.8
                        width = width < 100 ? 100 : width
                        let height = item.config.height * 0.8
                        height = height < 40 ? 40 : height
                        context.moveTo(item.config.x + width * 0.7, item.config.y + 30)
                        context.lineTo(item.config.x + width * 0.7 + height, item.config.y + height + 30)
                        context.moveTo(item.config.x + width * 0.7, item.config.y + height + 30)
                        context.lineTo(item.config.x + width * 0.7 + height, item.config.y + 30)
                        context.strokeStyle = "rgba(255,0,0,0.7)"
                        context.lineWidth = 4;
                        context.stroke()
                        // context.font = "60px Arial bolder"
                        // context.fillText('✘',item.config.x+(item.config.width*.6),item.config.y+90)
                    } else if (item.judge == 'halfRight') {
                        let width = item.config.width * 0.8
                        width = width < 200 ? 200 : width
                        let height = item.config.height
                        height = height < 200 ? 200 : height
                        context.moveTo(item.config.x + width * 0.7, item.config.y + height * 0.5)
                        context.lineTo(item.config.x + width * 0.7 + height * 0.1, item.config.y + height * 0.6)
                        context.moveTo(item.config.x + width * 0.7 + height * 0.1, item.config.y + height * 0.6)
                        context.lineTo(item.config.x + width * 0.7 + height * 0.4, item.config.y + height * 0.3)
                        context.moveTo(item.config.x + width * 0.7 + height * 0.25, item.config.y + height * 0.4)
                        context.lineTo(item.config.x + width * 0.7 + height * 0.3, item.config.y + height * 0.45)
                        context.strokeStyle = "rgba(255,0,0,0.7)"
                        context.lineWidth = 4;
                        context.stroke()
                        // context.font ="60px Arial bolder"
                        // context.fillText('⍻',item.config.x+(item.config.width*.6),item.config.y+90)
                    } else {
                        context.font = "20px Arial bolder"
                        context.fillText('未批改', item.config.x, item.config.y + 20)
                    }
                } else {
                    context.font = 50 + "px Arial bolder"
                    context.fillText(item.scoreLabel,  100,  100)
                }
            })
            let url = canvas.toDataURL('image/jpg')
            fn(url)
            // console.log(url)
            // newList[0]=url
            // console.log(newList)
            // that.imgList[key]=url
            // console.log(url)
            // return url
        }
    }
}
export {
    doDrawArea,
}