<!--
 * @Author: 艾斯
 * @Date: 2020-02-19 09:23:32
 * @LastEditTime: 2020-03-31 10:11:02
 * @LastEditors: Please set LastEditors
 * @Description: 作业列表
 * @FilePath: /vue/yunxue/src/views/TaskList.vue
 -->
<template>
    <div id="main">
        <van-sticky>
            <van-nav-bar
                    title="试题详情"
                    left-arrow
                    :left-text="'返回'"
                    @click-left="$router.go(-1)"
            />
        </van-sticky>
        <van-dropdown-menu v-if="correctableType !== 'studentNoExam'">
            <van-dropdown-item v-model="question" :options="questionData" @change="onLoad(true)"/>
        </van-dropdown-menu>
        <van-sticky :offset-top="topH">
            <div class="van-hairline--bottom">
                <van-row>
                    <van-col span="19">
                        <h4>{{ name }}</h4>
                    </van-col>
                    <van-col span="5">
                        <span class="title_r" v-if="correctableType !== 'studentNoExam'">{{ studentScore }}分</span>
                        <span class="title_r" style="font-size:12px" v-else>未交作业</span>
                    </van-col>
                </van-row>
            </div>
        </van-sticky>
        <div>
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
            >

                <van-cell v-for="(item, index) in list" :key="index">
                    <template slot="title">
                        <div class="cell_title">
                            <span>{{ item.showOrder }}．</span>
                            <span class="sub">[{{item.questionTypeName}}]</span>
                        </div>
                        <div class="cell_title">
                            <span v-html="item.questionParentText"></span>
                            <span v-html="item.text"></span>
                        </div>
                        <!--大题格式的小题正文部分-->
                        <div class="cell_title" v-for="(child,childKey) in item.children" :key="childKey">
                            <span>（{{child.showOrder}}）</span>
                            <span v-html="child.text"></span>
                        </div>
                        <div v-if="item.options.length > 0">
                            <van-row v-for="(rs, key) in item.options" :key="key" class="cell_tit_boder">
                                <van-col span="2">{{ rs.key }}.</van-col>
                                <van-col span="22">
                                    <div v-html="rs.value"></div>
                                </van-col>
                            </van-row>
                        </div>
                        <div class="cell_answer" v-if="correctableType !== 'studentNoExam'">
                            本题得分：{{ item.studentScore }}分
                            （满分：{{ item.fullMarkScore }}分<span v-if="item.estimateIncreaseScore>0"
                                                               style="color:#28a745">，可提分：{{item.estimateIncreaseScore}}分</span>）
                        </div>
                        <div class="cell_answer" v-else>本题满分：{{ item.fullMarkScore }}分</div>
                        <!--学生答案：选择题-->
                        <div v-if="item.pattern == 'multiple'">
                            <div class="cell_answer" v-if="correctableType !== 'studentNoExam'">
                                您的答案：{{ item.studentAnswer }}
                                <van-tag mark type="success" v-if="item.judge == 'allRight'">
                                    <van-icon class-prefix="my-icon" name="dui"/>
                                </van-tag>
                                <van-tag mark type="danger" v-if="item.judge == 'allWrong'">
                                    <van-icon class-prefix="my-icon" name="cuo"/>
                                </van-tag>
                                <van-tag mark type="warning" v-if="item.judge == 'halfRight'">
                                    <van-icon class-prefix="my-icon" name="bandui"/>
                                </van-tag>
                            </div>
                        </div>
                        <!--学生答案：按空批改-->
                        <div v-if="item.pattern == 'empty'">
                            <div class="cell_answer" v-if="correctableType !== 'studentNoExam' && testType !== 8">
                                您的答案：
                                <div style="text-align: center;">
                                    <!--                    <img :src="item.drawImg" @click="showImg(item.drawImg)"/>-->
                                    <van-image width="25rem" v-if="item.drawImg"
                                               height="5rem"
                                               fit="contain" :src="item.drawImg" @click="showImg(item.drawImg)"/>
                                </div>
                                <template v-if="item.onlineStudentAnswer.length>0">

                                    <div v-for="(rss, key) in item.onlineStudentAnswer" :key="key">
                                        {{ rss.label }}
                                        <van-image width="5rem" v-if="rss.imgUrl"
                                                   height="5rem"
                                                   fit="contain" :src="rss.imgUrl" @click="showImgDraw(rss)"/>
                                        <van-tag mark type="success" v-if="rss.judge == 'allRight'">
                                            <van-icon class-prefix="my-icon" name="dui"/>
                                        </van-tag>
                                        <van-tag mark type="danger" v-if="rss.judge == 'allWrong'">
                                            <van-icon class-prefix="my-icon" name="cuo"/>
                                        </van-tag>
                                        <van-tag mark type="warning" v-if="rss.judge == 'halfRight'">
                                            <van-icon class-prefix="my-icon" name="bandui"/>
                                        </van-tag>
                                    </div>
                                </template>
                                <template v-else-if="item.studentAnswer.length>0">
                                    <div v-for="(rss, key) in item.studentAnswer" :key="key">
                                        {{ rss.label }}
                                        <van-image width="5rem" v-if="rss.imgUrl"
                                                   height="5rem"
                                                   fit="contain" :src="rss.imgUrl" @click="showImgDraw(rss)"/>
                                        <van-tag mark type="success" v-if="rss.judge == 'allRight'">
                                            <van-icon class-prefix="my-icon" name="dui"/>
                                        </van-tag>
                                        <van-tag mark type="danger" v-if="rss.judge == 'allWrong'">
                                            <van-icon class-prefix="my-icon" name="cuo"/>
                                        </van-tag>
                                        <van-tag mark type="warning" v-if="rss.judge == 'halfRight'">
                                            <van-icon class-prefix="my-icon" name="bandui"/>
                                        </van-tag>
                                    </div>
                                </template>
                                <template v-else>
                                    <van-tag mark type="success" v-if="item.studentAnswer[0].judge == 'allRight'">
                                        <van-icon class-prefix="my-icon" name="dui"/>
                                    </van-tag>
                                    <van-tag mark type="danger" v-if="item.studentAnswer[0].judge == 'allWrong'">
                                        <van-icon class-prefix="my-icon" name="cuo"/>
                                    </van-tag>
                                    <van-tag mark type="warning" v-if="item.studentAnswer[0].judge == 'halfRight'">
                                        <van-icon class-prefix="my-icon" name="bandui"/>
                                    </van-tag>
                                    <img :src="item.studentAnswer[0].imgUrl"/>
                                </template>
                            </div>
                            <div class="cell_answer" v-if="testType == 8">
                                您的答案：
                                <div style="text-align: center;">

                                </div>
                                <template>
                                    <div style="padding: 5px 20px" v-for="(rss, key) in item.onlineStudentAnswer"
                                         :key="key">
                                        <!--                            <span>第【{{key}}】空: {{ rss.label }}</span>-->
                                        <span>{{ rss.label }}</span>
                                        <van-tag mark type="success" v-if="rss.judge == 'allRight'">
                                            <van-icon class-prefix="my-icon" name="dui"/>
                                        </van-tag>
                                        <van-tag mark type="danger" v-if="rss.judge == 'allWrong'">
                                            <van-icon class-prefix="my-icon" name="cuo"/>
                                        </van-tag>
                                        <van-tag mark type="warning" v-if="rss.judge == 'halfRight'">
                                            <van-icon class-prefix="my-icon" name="bandui"/>
                                        </van-tag>
                                    </div>
                                </template>

                            </div>
                        </div>
                        <!--学生答案：一题一区/多题一区/一题多区-->
                        <div v-if="item.pattern == 'wholeQuestion' || item.pattern == 'multipleQuestion' || item.pattern == 'splitQuestion'">
                            <div class="cell_answer" v-if="correctableType !== 'studentNoExam'">
                                您的答案：
                                <van-tag mark type="success" v-if="item.judge == 'allRight'">
                                    <van-icon class-prefix="my-icon" name="dui"/>
                                </van-tag>
                                <van-tag mark type="danger" v-if="item.judge == 'allWrong'">
                                    <van-icon class-prefix="my-icon" name="cuo"/>
                                </van-tag>
                                <van-tag mark type="warning" v-if="item.judge == 'halfRight'">
                                    <van-icon class-prefix="my-icon" name="bandui"/>
                                </van-tag>
                                <img :src="item.imgUrl"/>
                            </div>
                        </div>
                        <!--正确答案：选择题-->
                        <div class="cell_answer" v-if="item.pattern == 'multiple'">正确答案：{{ item.answer }}</div>
                        <!--正确答案：普通填空题-->
                        <div class="cell_answer" v-if="item.type=='empty_spaces'">
                            正确答案：
                            <template v-if="item.answer.length>1">
                                <div v-for="(rss, key) in item.answer" :key="key">
                                    {{rss.label}}
                                    <span v-html="rss.value"></span>
                                </div>
                            </template>
                            <template v-else-if="item.answer.length==1">
                                <span v-html="item.answer[0].value"></span>
                            </template>
                        </div>
                        <!--正确答案：大题格式-->
                        <div class="cell_answer" v-if="item.type=='big_spaces'">
                            正确答案：
                            <div v-for="(child,childKey) in item.children" :key="childKey">
                                <div v-for="(rss, key) in child.answer" :key="key">
                                    {{ rss.label}}
                                    <span v-html="rss.value"></span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="label">
                        <!--大题格式解答-->
                        <div class="cell_desc" v-if="item.type=='big_spaces'">
                            <van-row class="mb10">
                                <van-col span="3">解答：</van-col>
                                <van-col span="21">
                                    <div v-for="(child,childKey) in item.children" :key="childKey">
                                        <template v-if="child.jieda!=''">
                                            （{{child.showOrder}}）
                                            <span v-html="child.jieda"></span>
                                        </template>
                                    </div>
                                </van-col>
                            </van-row>
                        </div>
                        <!--普通题解答-->
                        <div class="cell_desc" v-else>
                            <van-row v-if="item.jieda" class="mb10">
                                <van-col span="3">解答：</van-col>
                                <van-col span="21" v-html="item.jieda"></van-col>
                            </van-row>
                        </div>
                    </template>
                </van-cell>

            </van-list>
        </div>
    </div>
</template>
<script>
    import {taskApi} from '@/api/index'
    import {replaceInfo} from '@/common/index'
    import {ImagePreview} from 'vant';
    import {doDrawArea} from '@/common/doDrawImg'

    export default {
        data() {
            return {
                name: '',
                studentScore: '',
                question: 0,//默认错题
                questionData: [
                    {text: '全部', value: 0},
                    {text: '错题', value: 1}
                ],
                start: 0,
                page: 1,
                testType: undefined,
                list: [],
                error: false,
                loading: false,
                finished: false,
                correctableType: this.$route.query.correctable,
                testId: this.$route.query.testId,
                myList: [],
            }
        },
        mounted() {
            if (this.correctableType == 'analyzed') {//已经结束分析
                this.question = 1;
                this.questionData.push({text: '可提分', value: 2});
            } else if (this.correctableType == 'studentNoExam') {//未考试，显示所有题目
                this.question = 0;
            }
        },
        computed: {
            topH() {
                let topH = '46px';
                let winW = document.documentElement.clientWidth || document.body.clientWidth;
                console.log('winW', winW);
                if (winW >= 768) {
                    topH = '92px';
                }
                return topH;
            },
        },
        methods: {
            textChange() {
                setTimeout(() => {
                    window.MathJax.Hub.Queue([
                        'Typeset',
                        window.MathJax.Hub,
                        document.getElementById('main')
                    ])
                }, 500)
            },
            onLoad(newData) {
                if (!this.testId) {
                    return
                }
                this.loading = true
                if (newData) {
                    this.finished = false;
                    this.start = 0
                    this.page = 1
                    this.list = []
                }
                let params = {
                    testId: this.testId,
                    start: this.start,
                    length: 10,
                    analysisType: this.question
                }
                taskApi.studentTestPaperQuestion(params).then(res => {
                    if (res.success) {
                        this.name = res.test.name
                        this.testType = parseInt(res.test.type)
                        this.studentScore = res.test.studentScore
                        if (res.data.length > 0) {
                            let data = res.data.map(item => {
                                // 处理图片路径
                                item = replaceInfo(item)
                                if (item.type == 'multiple_one' || item.type == 'multiple_many') {
                                    item.answer = item.answer.join(',')
                                    item.studentAnswer = item.studentAnswer.join(',')
                                }
                                item.drawImg = item.imgUrl
                                return item
                            })
                            this.list = [...this.list, ...data]
                            this.myList = this.list
                            // this.list.forEach(item => {
                            //     item.drawImg = item.imgUrl
                            //     this.myList.push(item)
                            // })
                            // this.list.map(item => {
                            //     this.doDrawImg(item)
                            // })
                            this.page++
                            this.start = (this.page - 1) * 10

                            // 加载状态结束
                            this.loading = false
                            this.textChange()
                            /*setTimeout(() => {
                                this.list = [...this.list, ...data]
                                this.myList = []
                                this.list.forEach(item => {
                                    item.drawImg = item.imgUrl
                                    this.myList.push(item)
                                })
                                // this.list.map(item => {
                                //     this.doDrawImg(item)
                                // })
                                this.page++
                                this.start = (this.page - 1) * 10

                                // 加载状态结束
                                this.loading = false
                                this.textChange()
                            }, 500)*/
                        } else {
                            this.finished = true
                        }
                    } else {
                        this.error = true
                    }
                })
            },
            //画改分痕迹
            doDrawImg(obj) {
                const that = this
                // this.myList[i]=obj
                doDrawArea(obj.imgUrl, obj.studentAnswer, function (e) {
                    obj.drawImg = e
                    that.myList.push(obj)
                })
            },
            // 显示图片
            showImg(url) {
                ImagePreview({
                    images: [url],
                    startPosition: 0,
                })
            },
            showImgDraw(obj) {
                doDrawArea(obj.imgUrl, [obj.config], function (e) {
                    ImagePreview({
                        images: [e],
                        startPosition: 0,
                    })
                })

            }
        },
        watch: {
            $route(to, from) {
                this.correctableType = to.query.correctableType
                this.testId = to.query.testId
                this.onLoad(true)
            }
        },
    }
</script>
<style lang="scss" scoped>
    #main {
        text-align: left;
    }

    ::v-deep img {
        max-width: 90%;
    }

    ::v-deep table,
    table tr th,
    ::v-deep table tr td {
        border: 1px solid #ccc;
        padding: 2px;
    }

    ::v-deep table {
        border-collapse: collapse;
        width: 90%;
    }

    .van-hairline--bottom {
        background-color: #fff;
    }

    h4 {
        margin: 0;
        padding: 12px;
    }

    .title_r {
        float: right;
        color: red;
        margin: 10px 10px 0 0;
    }

    .cell_title {
        width: 93vw;
        font-size: 16px;
        margin-bottom: 15px;
        overflow-x: auto;

        .sub {
            color: #1989fa;
        }
    }

    .cell_tit_boder {
        width: 90%;
        overflow-x: auto;
        border-bottom: 1px dashed #ebedf0;
        // line-height: 1.5;
        margin-bottom: 10px;
        padding: 6px 0;
        color: #606266;
    }

    .cell_answer {
        width: 90%;
        overflow-x: auto;
        margin-bottom: 10px;

        .van-tag {
            margin-left: 8px;
            margin-top: -2px;
        }
    }

    .cell_desc {
        width: 90%;
        overflow-x: auto;
        position: relative;
        line-height: 1.75;

        .mr10 {
            margin-right: 10px;
        }

        .mb10 {
            margin-bottom: 12px;
        }
    }
</style>
